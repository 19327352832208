import { marked, MarkedOptions, Token } from "marked";
import sanitizeHtml from "./sanitizeHtml";

const underline = <const>{
  start: /\+\+/,
  content: /^\+\+(?=\S)([\s\S]*?\S)\+\+(?!\+)/,
};

namespace Tokens {
  export type Underline = {
    type: "underline";
    raw: string;
    tokens: Token[];
  };
}

marked.use({
  extensions: [
    {
      name: "underline",
      level: "inline",
      start(src: string) {
        return src.match(underline.start)?.index as number; // Hint to Marked.js to stop and check for a match
      },
      tokenizer(src: string): Tokens.Underline | undefined {
        const match = underline.content.exec(src);
        if (match) {
          return {
            // Token to generate
            type: "underline", // Should match "name" above
            raw: match[0], // Text to consume from the source
            tokens: this.lexer.inlineTokens(match[1], []),
          };
        }
      },
      renderer(token: Tokens.Underline) {
        return `<u>${this.parser.parseInline(
          token.tokens,
          this.parser.renderer
        )}</u>`;
      },
    },
  ],
});

export function safeMarked(text: string, markedOptions?: MarkedOptions) {
  return marked(sanitizeHtml(text), { ...markedOptions, async: false,  });
}
