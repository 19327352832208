import DOMPurify from "dompurify";

DOMPurify.addHook("uponSanitizeElement", function (node: HTMLElement, element) {
  if (element.tagName === "a") {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer");
  }
});

export default function sanitizeHtml(html?: string) {
  if (!html) return "";
  return DOMPurify.sanitize(html, {
    USE_PROFILES: { html: true },
    FORBID_TAGS: [
      "script",
      "img",
      "video",
      "audio",
      "iframe",
      "svg",
      "canvas",
      "embed",
      "object",
      "source",
      "track",
      "param",
      "select",
      "option",
      "p",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
    ],
    ALLOWED_TAGS: [
      "a",
      "b",
      "i",
      "u",
      "em",
      "strong",
      "code",
      "li",
      "ul",
      "ol",
      "span",
      "div",
      "p",
    ],
    ALLOWED_ATTR: ["href", "target", "rel", "title", "class", "id", "style"],
  }).trim();
}
