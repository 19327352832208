import "@utils/marked";
import { safeMarked } from "@utils/marked";
import addReadWhen from "@utils/storeUtils/addReadWhen";
import { extendSvelteWritable } from "@utils/storeUtils/writableExtendable";
import dayjs from "dayjs";
import "dayjs/locale/es";
import store from "src_launcher/store";
import {
  addMessages,
  getLocaleFromNavigator,
  init,
  isLoading,
} from "svelte-i18n";
import en from "./en.json";
import es from "./es.json";

const currentLocale = (getLocaleFromNavigator() || "en").slice(0, 2);

dayjs.locale(currentLocale);

addMessages("en", en);
addMessages("es", es);

init({ fallbackLocale: "en", initialLocale: currentLocale });

export const Locale = extendSvelteWritable(isLoading)
  .extend(addReadWhen)
  .extend(store => ({
    get currentLocale() {
      return currentLocale;
    },
    isReady() {
      return store.readWhen($isLoading => !$isLoading);
    },
  }));

Locale.isReady().then(() => {
  store.rootModel.config.subscribe($config => {
    if ($config.loading) return;

    const {
      merchantName,
      prechatInfo,
      prechatInfoEn,
      habeasdataText,
      habeasdataTextEn,
      emptyTimeline,
      emptyTimelineEn,
    } = $config;

    const termsEn = safeMarked(
      habeasdataTextEn || habeasdataText || "Accept Terms and Conditions"
    );

    addMessages("en", {
      "titlebar:merchant-name": merchantName || " ",
      "welcome-message": emptyTimelineEn || emptyTimeline,
      "prechat:info": prechatInfoEn || prechatInfo,
      "prechat:terms": termsEn,
    });

    const termsEs = safeMarked(
      habeasdataText || "Aceptar Términos y Condiciones"
    );

    addMessages("es", {
      "titlebar:merchant-name": merchantName || " ",
      "welcome-message": emptyTimeline,
      "prechat:info": prechatInfo,
      "prechat:terms": termsEs,
    });
  });
});
